var phoneNumberFormat = require('google-libphonenumber').PhoneNumberFormat;
var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const isValidNumber = function (number) {
	try {
		return phoneUtil.isValidNumber(phoneUtil.parse(number));
	} catch (e) {
		return false;
	}
};

const format = function (number, countryCode, formatType) {
	try {
		var result = number;
		var originalPhoneNumber = phoneUtil.parseAndKeepRawInput(number, countryCode);
		switch (formatType) {
			case 'INTERNATIONAL':
				result = phoneUtil.format(originalPhoneNumber, phoneNumberFormat.INTERNATIONAL);
				break;
			case 'NATIONAL':
				result = phoneUtil.format(originalPhoneNumber, phoneNumberFormat.NATIONAL);
				break;
			case 'E164':
				result = phoneUtil.format(originalPhoneNumber, phoneNumberFormat.E164);
				break;
			default:
				return;
		}

		return result;
	} catch (e) {
		return number;
	}
};

const getCountryCodeForNumber = function (number) {
	try {
		return phoneUtil.getRegionCodeForNumber(phoneUtil.parse(number));
	} catch (e) {
		return null;
	}
};

const getDialCodeForNumberAndCountryCode = function (number, countryCode) {
	try {
		var originalPhoneNumber = phoneUtil.parseAndKeepRawInput(number, countryCode);
		return originalPhoneNumber.getCountryCode();
	} catch (e) {
		return null;
	}
};

const getCountryCodeForRegion = region => {
	try {
		return phoneUtil.getCountryCodeForRegion(region);
	} catch (e) {
		return null;
	}
};

module.exports = {
	isValidNumber,
	format,
	getCountryCodeForNumber,
	getDialCodeForNumberAndCountryCode,
	getCountryCodeForRegion
};
