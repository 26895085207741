import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@upsales/components';
import './BookingTimePickerButton.css';
import { Typography } from '../../types/typography';
import { Colors } from '../../types/colors';
import { getStyle } from '../../helpers/style';

interface Props {
	startTime: string;
	endTime: string;
	setTime(time: any): any;
	onConfirm(): any;
	showConfirm: boolean;
	colors: Colors | null;
	typography: Typography | null;
}

function BookingTimePickerButton(props: Props) {
	const { t } = useTranslation();

	function handleTimeSelected() {
		if (props.showConfirm) {
			props.setTime('');
		} else {
			props.setTime(props.startTime + ' - ' + props.endTime);
		}
	}

	return (
		<div
			className={
				'BookingTimePickerButtonWrap ' + (props.showConfirm ? ' BookingTimePickerButtonWrap--showConfirm' : '')
			}
		>
			<Button
				className="PickTimeButton"
				type={props.showConfirm ? null : 'lined'}
				shadow="none"
				onClick={handleTimeSelected}
				style={{
					...getStyle('buttonsCalendarDays', 'timeText', props.colors, props.typography),
					borderColor: props.colors?.timeText
				}}
			>
				{props.startTime}
				{!props.showConfirm ? ' - ' + props.endTime : null}
			</Button>
			{props.showConfirm ? (
				<Button
					className="ConfirmTimeButton"
					onClick={props.onConfirm}
					style={{
						...getStyle('buttonsCalendarDays', 'butttonText', props.colors, props.typography),
						backgroundColor: props.colors?.butttonBg,
						borderColor: props.colors?.timeText
					}}
				>
					{t('bookingTimePickerButton.confirm')}
				</Button>
			) : null}
		</div>
	);
}

export default BookingTimePickerButton;
