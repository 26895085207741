import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Row, Headline } from '@upsales/components';
import './Error500.scss';
function Error500() {
	const { t } = useTranslation();
	return (
		<div className="Error500">
			<Row direction="column">
				<Row direction="column">
					<Headline id="errorCode" color="grey-4" size="xl">
						{'500'}
					</Headline>
				</Row>
				<Row direction="column" id="noRespond">
					<Headline id="headerError500" size="sm">
						{t('error500.notResponding')}
					</Headline>
				</Row>
				<Row direction="column">
					<Text className="subText" center={true} color="grey-10" bold={true}>
						{t('error500.message')}
					</Text>
					<Text className="subText" center={true} color="grey-10" bold={true}>
						{t('error500.checkBack')}
					</Text>
				</Row>
			</Row>
		</div>
	);
}
export default Error500;
