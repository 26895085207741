import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Avatar, Column, Row, Text, Link, Title, Icon } from '@upsales/components';
import './SideView.scss';
import { Typography } from '../../types/typography';
import { ColorsType } from '../../types/colors';
import { getStyle } from '../../helpers/style';
interface Props {
	userInfo: any;
	time: string;
	date: string;
	showDescription: boolean;
	colors: ColorsType | null;
	typography: Typography | null;
	displayLogo: boolean;
	showPhoneEmail: boolean;
	isShared: boolean;
}

function SideView(props: Props) {
	const { t } = useTranslation();

	return (
		<div className="sideView" style={props.colors ? { backgroundColor: props.colors?.sidePanelBg } : {}}>
			{props.userInfo.companyLogo && props.displayLogo ? (
				<div className="contactCard" data-testid="contactCardTest">
					<img
						src={props.userInfo.companyLogo}
						alt="company logo"
						className="companyLogo"
						data-testid="companyImgTest"
					/>
				</div>
			) : (
				<div className="contactCard filler" />
			)}
			<div id="contentWrap">
				{!props.isShared ? (
					<Row id="userWrap">
						<Column id="contactAvatar" fixedWidth={70}>
							<Avatar
								id="contactImage"
								data-testid="contactImgTest"
								size="lg"
								type="round"
								src={props.userInfo.avatarSrc}
								initials={props.userInfo.name}
								email={props.userInfo.email}
							/>
						</Column>
						{/**Check if show phone & mail is true */}
						<Column id="contactInfo" data-testid="contactInfoTest">
							<Title id="contactName" bold={true} size="md">
								{props.userInfo.name}
							</Title>
							<Text id="contactTitle" data-testid="contactTitleTest" size="sm">
								{props.userInfo.title}
							</Text>
							{props.showPhoneEmail ? (
								<React.Fragment>
									<Link href={`tel:${props.userInfo.phone}`}>
										<Icon name="mobile" />
										<Text size="sm" color="bright-blue">
											{props.userInfo.phone}
										</Text>
									</Link>
									<Link href={`mailto:${props.userInfo.email}`}>
										<Row id="contactEmail">
											<Icon name="email" />
											<Column id="contactEmailText">
												<Text size="sm" color="bright-blue">
													{props.userInfo.email}
												</Text>
											</Column>
										</Row>
									</Link>
								</React.Fragment>
							) : null}
						</Column>
					</Row>
				) : null}
				{props.showDescription ? (
					<Row
						id="description"
						data-testid="descriptionTest"
						style={props.isShared ? { marginTop: 'unset' } : {}}
					>
						<Row id="descriptionTitle" data-testid="descriptionTitleTest">
							<Title
								size="sm"
								style={getStyle('description', 'descriptionText', props.colors, props.typography)}
								bold={props.typography?.description.type === '' ? true : false}
							>
								{props.userInfo.descriptionTitle}
							</Title>
							{props.userInfo.descriptionTime.length === 1 ? (
								<Text
									id="descriptionTime"
									data-testid="descriptionTimeTest"
									style={getStyle('description', 'descriptionText', props.colors, props.typography)}
								>
									{props.userInfo.descriptionTime} {t('sideView.minutes')}
								</Text>
							) : null}
						</Row>
						<Row id="descriptionChosenDate">
							{props.date !== '' ? (
								<Row className="DescriptionRow" data-testid="descriptionChosenDateDR">
									<Icon
										name="calendar"
										style={props.colors ? { color: props.colors.messageText } : {}}
									/>
									<Text
										size="md"
										style={getStyle('message', 'messageText', props.colors, props.typography)}
									>
										{moment(props.date).format('dddd Do MMMM')}
									</Text>
								</Row>
							) : null}
						</Row>
						<Row id="descriptionChosenTime">
							{props.time ? (
								<Row className="DescriptionRow descriptionChosenTime">
									<Icon
										name="clock-o"
										style={props.colors ? { color: props.colors.messageText } : {}}
									/>
									<Text
										size="md"
										style={getStyle('message', 'messageText', props.colors, props.typography)}
									>
										{' '}
										{props.time}
									</Text>
								</Row>
							) : null}
						</Row>
						<Row id="descriptionTextWrap">
							<div
								id="descriptionText"
								data-testid="descriptionTextTest"
								dangerouslySetInnerHTML={{ __html: props.userInfo.descriptionText }}
								style={getStyle('message', 'messageText', props.colors, props.typography)}
							></div>
						</Row>
					</Row>
				) : null}
			</div>
		</div>
	);
}

export default SideView;
