import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Row, Block, Title, Icon } from '@upsales/components';
import './BookingConfirmed.scss';
import ConfirmedMeeting from '../../assets/ConfirmedMeeting.json';
import Lottie from 'react-lottie';
import moment from 'moment';

type Props = {
	data: {
		name: string;
		email: string;
		phone: string;
		company: string;
		time: string;
		date: string;
	};
	timezone: string;
};

function BookingConfirmed({ data, timezone }: Props) {
	const { t } = useTranslation();
	const lang = {
		confimed: t('bookingConfirmed.confimed'),
		sent: t('bookingConfirmed.invitation')
	};

	const options = {
		loop: false,
		animationData: ConfirmedMeeting
	};

	const date = moment(data.date).format('dddd Do MMMM, YYYY');

	return (
		<div className="bookingConfirmedContainer" data-testid="bookingConfirmedDiv">
			<Block border="bs" borderColor="grey-4" className="confirmedBookingTitle" data-testid="calendarCheck">
				<Title size="xl" bold={true} color="bright-green">
					{lang.confimed}
				</Title>
				<Lottie options={options} height={64} width={64} />
			</Block>
			<Block className="bookingInfo">
				<Row>
					<Icon name="calendar-check-o" color="grey-11" />
					<Text color="grey-11">{data.time + ', ' + date}</Text>
				</Row>
				<Row>
					<Icon name="globe" color="grey-11" />
					<Text color="grey-11">{timezone}</Text>
				</Row>
			</Block>
			<div className="information">
				<Icon name="info" color="bright-blue" />
				<Text color="bright-blue">{lang.sent}</Text>
			</div>
		</div>
	);
}

export default BookingConfirmed;
