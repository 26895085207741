import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import phoneNumberHelper from '../../phoneNumberHelper';
import intlTelInput from 'intl-tel-input';
import bemClass from '@upsales/components/Utils/bemClass';
import { withTranslation } from 'react-i18next';
import { Text, Button } from '@upsales/components';
import './PhoneInput.scss';
const classes = new bemClass('phone-input');

const DEFAULT_COUNTRY = (new Intl.Locale(navigator.language)).region ?? 'SE';

class PhoneInput extends PureComponent {
	defaultCountry = DEFAULT_COUNTRY;

	defaultCountryCode = phoneNumberHelper.getCountryCodeForRegion(this.defaultCountry);

	constructor(p) {
		super(p);
		this.state = {
			iti: null,
			phone: null,
			phoneMounted: true
		};

		this.lang = {
			phoneNumberCodeError: this.props.t('bookingForm.phone.countryCodeError'),
			useCode: this.props.t('bookingForm.phone.useCountryCode') + this.defaultCountryCode
		};
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		setTimeout(() => {
			if (this.state.phoneMounted && this.props && this.props.isMounted) {
				this.init();
			}
		}, 100);
	}

	componentWillUnmount() {
		this.setState({ phoneMounted: false });
		const input = this.inputRef.current;
		input.removeEventListener('countrychange', e => {
			const countryCode = this.state.iti.getSelectedCountryData().iso2;
			const parsedPhoneNumberObj = this.parsePhoneNumber(e.target.value, countryCode);
			this.phoneOnChange(parsedPhoneNumberObj.phone);
		});
	}

	init() {
		const phoneNumberCountryCode = this.getPhoneNumberCountryCode(this.props.phone);
		const phone = this.parsePhoneNumber(this.props.phone, phoneNumberCountryCode).phone;
		const input = this.inputRef.current;
		let iti = null;

		if (input) {
			iti = intlTelInput(input, {
				preferredCountries: [phoneNumberCountryCode],
				initialCountry: phoneNumberCountryCode,
				separateDialCode: true
			});
			input.addEventListener('countrychange', e => {
				const countryCode = this.state.iti.getSelectedCountryData().iso2;
				const parsedPhoneNumberObj = this.parsePhoneNumber(e.target.value, countryCode);
				this.phoneOnChange(parsedPhoneNumberObj.phone);
			});
		}
		this.setState({ iti, phone });
		this.setCountryCode();

		const formatedPhoneNumber = this.formatNumber();
		const isValid = phoneNumberHelper.isValidNumber(formatedPhoneNumber);
		const shouldUpdate = isValid && this.props.phone !== formatedPhoneNumber;

		if (shouldUpdate) {
			this.props.onChange(formatedPhoneNumber);
		}
	}

	phoneOnChange = e => {
		const number = e.target ? e.target.value : e;
		const { iti } = this.state;
		const iso = iti ? iti.getSelectedCountryData().iso2 : null;
		const countryCode = phoneNumberHelper.getCountryCodeForNumber(number)
			? phoneNumberHelper.getCountryCodeForNumber(number)
			: iso;
		const parsedPhoneNumberObj = this.parsePhoneNumber(number, countryCode);
		this.setState({
			phone: iti ? parsedPhoneNumberObj.phone : number
		});
		if (iti && countryCode) {
			iti.setCountry(countryCode);
		}

		const parsedNumber = number.length > 3 ? phoneNumberHelper.format(number, countryCode, 'E164') : number;
		this.props.onChange(parsedNumber);
	};

	parsePhoneNumber(number, countryCode) {
		const dialCode = phoneNumberHelper.getDialCodeForNumberAndCountryCode(number, countryCode);
		let phone = phoneNumberHelper.format(number, countryCode, 'INTERNATIONAL');

		if (dialCode) {
			phone = phone.substring(`+${dialCode}`.length);
		}

		return {
			phone: phone,
			dialCode: dialCode
		};
	}

	formatNumber = (number, countryNumber) => {
		const { iti } = this.state;
		const phone = number ? number : this.state.phone;
		const iso = iti ? iti.getSelectedCountryData().iso2 : countryNumber;
		const cCode = phoneNumberHelper.getCountryCodeForNumber(phone);
		const countryCode = cCode ? cCode : iso;

		return phoneNumberHelper.format(phone, countryCode, 'E164');
	};

	getPhoneNumberCountryCode(number) {
		const { isNew } = this.props;
		const countryCode = phoneNumberHelper.getCountryCodeForNumber(number);

		if (isNew) return this.defaultCountry || DEFAULT_COUNTRY;

		if (!countryCode) return 'auto';

		return countryCode.toString();
	}

	onCopy = event => {
		event.clipboardData.setData('text/plain', this.formatNumber());
		event.preventDefault();
	};

	setCountryCode = () => {
		if (this.state.iti && this.state.iti.setCountry) {
			this.state.iti.setCountry(this.defaultCountry);
		}
	};

	render() {
		const { name, iconClass, required } = this.props;
		const isValid = phoneNumberHelper.isValidNumber(this.formatNumber());
		const telInput = this.state.iti || {};
		const isCountryCode = this.defaultCountryCode && this.defaultCountry;

		return (
			<Fragment>
				<div className={classes.b() + ' input-group' + (this.state.phone && !isValid ? ' has-error' : '')}>
					{iconClass ? (
						<span className="input-group-addon">
							<i className={'fa fa-' + iconClass} />
						</span>
					) : null}
					<input
						id={name}
						name={name}
						autoComplete="off"
						maxLength="30"
						value={this.state.phone || ''}
						onChange={this.phoneOnChange}
						onCopy={this.onCopy}
						className="form-control"
						ref={this.inputRef}
						data-testid="phone"
						required={required}
					/>
				</div>

				{this.state.phone && !telInput.defaultCountry && isCountryCode ? (
					<Text color="red" size="sm" className={classes.elem('empty-code').b()}>
						{this.lang.phoneNumberCodeError}{' '}
						<Button type="link" size="sm" onClick={this.setCountryCode}>
							{this.lang.useCode}
						</Button>
					</Text>
				) : null}
			</Fragment>
		);
	}
}

PhoneInput.propTypes = {
	phone: PropTypes.string,
	name: PropTypes.string,
	iconClass: PropTypes.string,
	onChange: PropTypes.func,
	isNew: PropTypes.bool,
	required: PropTypes.bool
};

export default withTranslation()(PhoneInput);
