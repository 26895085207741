import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Headline, Row, Text } from '@upsales/components';
import './Error400.scss';
import timeThief from '../../assets/time-thief.png';

function Error400() {
	const { t } = useTranslation();
	return (
		<div className="Error400">
			<Row direction="column">
				<Row direction="column">
					<img id="timeThief" src={timeThief} alt="TimeThief"></img>
				</Row>
				<Row direction="column">
					<Headline id="headerError400" size="sm">
						{t('error400.headline')}
					</Headline>
				</Row>
				<Row direction="column">
					<Text id="subText400" color="grey-10" center={true} size="lg">
						{t('error400.message')}
					</Text>
				</Row>
				<Row direction="column">
					<Button
						id="anotherTimeButton"
						text={t('error400.anotherTimeButtonText')}
						size="lg"
						onClick={() => window.location.reload()}
					/>
				</Row>
			</Row>
		</div>
	);
}

export default Error400;
