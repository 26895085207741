import { TypographyTypes, Typography } from '../types/typography';
import { ColorTypes, ColorsType } from '../types/colors';

export const getStyle = (
	typography: TypographyTypes,
	color: ColorTypes,
	colors: ColorsType | null,
	typographies: Typography | null
) => {
	if (colors !== null && typographies !== null && typographies !== undefined) {
		const type = typographies[typography].type;

		let style: { [key: string]: string } = {
			fontFamily: typographies[typography].typography,
			color: colors[color]
		};

		switch (type) {
			case 'bold':
				style = { ...style, fontWeight: 'bold' };
				break;
			case 'italic':
				style = { ...style, fontStyle: 'italic' };
				break;
			case 'underline':
				style = { ...style, textDecoration: 'underline' };
				break;
		}

		return style;
	}

	return {};
};
