import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './lang/en.json';
import translationSV from './lang/sv-SE.json';

const resources = {
	en: {
		translation: translationEN
	},
	sv: {
		translation: translationSV
	}
};

i18n.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		detection: {
			order: ['navigator', 'sessionStorage', 'localStorage']
		},
		resources,
		fallbackLng: 'en',
		keySeparator: false,
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
