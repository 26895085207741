import React from 'react';
import { Row, Icon, Text } from '@upsales/components';
import './SearchableSelect.scss';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

interface Props {
	default: object;
	options: object[];
	onChange(choice: object): void;
	setTimezone(value: object): void;
}

function SearchableSelect(props: Props) {
	const handleSelection = (choice: any) => {
		props.setTimezone(choice);
		props.onChange(choice);
	};
	const { t } = useTranslation();

	return (
		<Row data-testid="timezonePicker" className="bottomRow">
			<Select
				className="selectContainer-container"
				classNamePrefix="selectContainer"
				value={props.default}
				options={props.options}
				onChange={handleSelection}
			/>
			<div className="legendWrapper">
				<Text>
					<Icon name="square" color="grey-3" className="unavailableTime" />
					{t('legend.unavailable')}
				</Text>

				<Text id="available">
					<Icon name="square-o" color="grey-3" className="availableTime" />
					{t('legend.available')}
				</Text>
			</div>
		</Row>
	);
}

export default SearchableSelect;
