import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { Text, Row, Headline } from '@upsales/components';
import animationData from '../../assets/DisabledEasyBooking.json';
import './Error400InactivePage.scss';

function InactiveCalendar() {
	const { t } = useTranslation();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div className="inactiveCalendarContainer">
			<Row direction="column" id="container">
				<Row direction="column" id="animation">
					<Lottie options={defaultOptions} height={320} width={320} />
				</Row>
				<Headline id="headerInactiveCalendar" size="sm">
					{t('inactiveCalendar.header')}
				</Headline>
				<Text id="subTextInactiveCalendar" color="grey-10" size="lg" center={true}>
					{t('inactiveCalendar.subText')}
				</Text>
			</Row>
		</div>
	);
}

export default InactiveCalendar;
