import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Headline } from '@upsales/components';
import './NoAvailableTimes.scss';

interface NoAvailableTimesProps {
	name: string;
}
function NoAvailableTimes(props: NoAvailableTimesProps) {
	const { t } = useTranslation();
	return (
		<div id="noAvailableTimesContainer">
			<span id="emoji" role="img" aria-label="man_shrugging">
				{`🤷‍♂️`}
			</span>
			<Headline id="headerNoAvailableTimes" size="sm">
				{t('noAvailableTimes.noAvailable')} {props.name}
			</Headline>
			<Text id="subTextNoAvailable" color="grey-10" size="lg" center>
				{props.name} {t('noAvailableTimes.message')}
			</Text>
		</div>
	);
}

export default NoAvailableTimes;
