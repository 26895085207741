import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Row, Headline } from '@upsales/components';
import './Error404.scss';
import Sadeventenvelope from '../../assets/SadEventEnvelope.svg';

interface Error404Props {
	companyLogo: string;
}
function Error404(props: Error404Props) {
	const { t } = useTranslation();
	return (
		<div className="Error404">
			{props.companyLogo ? <img id="companyLogo" alt="companyLogo" src={props.companyLogo}></img> : null}
			<Row direction="column">
				<Row direction="column">
					<img id="sadEvent" alt="sadEventImg" src={Sadeventenvelope}></img>
				</Row>
				<Row direction="column">
					<Headline id="headerError" size="sm">
						{t('error404.linkBroken')}
					</Headline>
				</Row>
				<Row direction="column">
					<Text id="subText" color="grey-10" center={true}>
						{t('error404.message')}
					</Text>
				</Row>
			</Row>
		</div>
	);
}

export default Error404;
