import React from 'react';
import { Title, ButtonSelect } from '@upsales/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import './BookingTimePicker.scss';
import BookingTimePickerButton from '../BookingTimePickerButton';
import moment from 'moment';
import { Typography } from '../../types/typography';
import { Colors } from '../../types/colors';
import { getStyle } from '../../helpers/style';
import Select from 'react-select';

interface BookingTimePickerProps {
	date: Date | null;
	setTime(time: any): any;
	setFormActive(active: boolean): any;
	appointmentTimes: any;
	colors: Colors | null;
	typography: Typography | null;
	appointmentLengths: number[];
	setAppointmentTimes: any;
}
interface BookingTimePickerState {
	tempDate: string;
	selectedTime: string;
	selectedLength: number;
	allTimes: any;
}

class BookingTimePicker extends React.Component<BookingTimePickerProps & WithTranslation, BookingTimePickerState> {
	constructor(props: BookingTimePickerProps & WithTranslation) {
		super(props);

		const tempDate = moment(this.props.date).format('YYYY-MM-DD');
		this.state = {
			tempDate,
			selectedTime: '',
			selectedLength: this.props.appointmentLengths[0],
			allTimes: this.setAllTimes(this.props.appointmentLengths)
		};

		this.handleSetTime = this.handleSetTime.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
	}

	componentDidUpdate(prevProps: BookingTimePickerProps & WithTranslation) {
		if (prevProps.date !== this.props.date) {
			const tempDate = moment(this.props.date).format('YYYY-MM-DD');
			this.setState({ tempDate, selectedTime: '' });
		}
	}

	handleSetTime(time: string) {
		this.setState({
			selectedTime: time
		});
	}

	handleConfirm() {
		this.props.setTime(this.state.selectedTime);
		this.props.setFormActive(true);
	}

	getTimeName(time: number, short: boolean): string {
		const quotient = Math.floor(time / 60);
		const remainder = time % 60;
		const singleHourText = ' ' + this.props.t('sideView.hour.long');
		const hourText = short ? ' ' + this.props.t('sideView.hours') : ' ' + this.props.t('sideView.hours.long');
		const minuteText = short ? ' ' + this.props.t('sideView.minutes') : ' ' + this.props.t('sideView.minutes.long');
		if (quotient > 0) {
			// include hours
			if (remainder === 0) {
				// no minutes
				if (quotient === 1 && !short) {
					// special case, hours in singular
					return `${1}${singleHourText}`;
				} else {
					return `${quotient}${hourText}`;
				}
			} else {
				// hours and minutes
				return `${quotient}${hourText} ${remainder}${minuteText}`;
			}
		} else {
			// only minutes
			return `${time}${minuteText}`;
		}
	}
	setAllTimes(times: number[]) {
		const tempTimes: any = {};
		times.forEach(time => {
			tempTimes[time] = {};
			tempTimes[time]['short'] = this.getTimeName(time, true);
			tempTimes[time]['long'] = this.getTimeName(time, false);
		});
		return tempTimes;
	}

	times() {
		if (this.props.appointmentTimes && this.props.appointmentTimes[this.state.tempDate]) {
			return this.props.appointmentTimes[this.state.tempDate].map((time: any, index: number) => {
				const pickerTime = time.startTime.format('HH:mm') + ' - ' + time.endTime.format('HH:mm');
				return (
					<BookingTimePickerButton
						startTime={time.startTime.format('HH:mm')}
						endTime={time.endTime.format('HH:mm')}
						setTime={this.handleSetTime}
						onConfirm={this.handleConfirm}
						key={index}
						showConfirm={this.state.selectedTime === pickerTime}
						colors={this.props.colors}
						typography={this.props.typography}
					/>
				);
			});
		}
		return null;
	}

	appointmentLengthSelectData() {
		return Object.keys(this.state.allTimes).map(key => {
			return { label: this.state.allTimes[key]['long'], value: key };
		});
	}

	getTimeFromLabel(label: string): number {
		for (const key of Object.keys(this.state.allTimes)) {
			if (this.state.allTimes[key]['short'] === label) {
				return parseInt(key);
			}
		}
		return -1;
	}

	render() {
		const appointmentsLengthsCount = this.props.appointmentLengths.length;
		const appointmentLengths = Object.values(this.state.allTimes).map((value: any) => value['short']);
		const lengthShort: string = this.state.allTimes[this.state.selectedLength]['short'];
		const lengthLong: string = this.state.allTimes[this.state.selectedLength]['long'];
		return (
			<div className="BookingTimePicker">
				<Title
					size="xl"
					data-testid="SelectTimeTitle"
					style={getStyle('headlines', 'headlineText', this.props.colors, this.props.typography)}
				>
					{this.props.t('bookingTimePicker.selectTime')}
				</Title>
				<Title
					size="sm"
					className="Date"
					data-testid="DateString"
					style={getStyle('message', 'messageText', this.props.colors, this.props.typography)}
				>
					{moment(this.props.date).format('dddd Do MMMM')}
				</Title>
				<div className="appointmentLengthSelector">
					{appointmentsLengthsCount > 4 ? (
						<Select
							data-testid="Select"
							onChange={(e: any) => {
								this.setState({ selectedLength: e.value });
								this.props.setAppointmentTimes(parseInt(e.value));
							}}
							options={this.appointmentLengthSelectData()}
							value={{ value: this.state.selectedLength.toString(), label: lengthLong }}
						/>
					) : appointmentsLengthsCount <= 4 && appointmentsLengthsCount > 1 ? (
						<React.Fragment>
							<Title size="sm" bold={true}>
								{this.props.t('bookingTimePicker.selectAppointmentLength')}
							</Title>
							<ButtonSelect
								data-testid="ButtonSelect"
								options={appointmentLengths}
								onChange={(e: any) => {
									const value = this.getTimeFromLabel(e);
									this.setState({ selectedLength: value });
									this.props.setAppointmentTimes(value);
								}}
								value={lengthShort}
							/>
						</React.Fragment>
					) : null}
				</div>
				<div className="timePickerButtons" data-testid="TimePickerButtonWrap">
					{this.times()}
				</div>
			</div>
		);
	}
}

export default withTranslation()(BookingTimePicker);
