import React, { useState, useEffect, useMemo } from 'react';
import { Title, Calendar, CalendarHeader } from '@upsales/components';
import { useTranslation } from 'react-i18next';
import './BookingCalendar.scss';
import BookingTimePicker from '../BookingTimePicker';
import moment from 'moment-timezone';
import _ from 'lodash';
import SearchableSelect from '../SearchableSelect';
import { Typography } from '../../types/typography';
import { Colors } from '../../types/colors';
import { getStyle } from '../../helpers/style';

interface Props {
	timezone: string;
	setDate(date: any): any;
	setTime(time: any): any;
	setFormActive(active: boolean): any;
	setTimezone(newTimezone: string): any;
	appointmentTimes: any;
	colors: Colors | null;
	typography: Typography | null;
	appointmentLengths: number[];
}

function BookingCalendar(props: Props) {
	const { t } = useTranslation();
	const validZones = _.filter(moment.tz.names(), function (name) {
		return !name.startsWith('Etc');
	});
	const _availableTZ = _.map(validZones, function (name) {
		const momentZone = moment.tz.zone(name);
		if (momentZone) {
			const utcOffset = moment(new Date()).tz(momentZone.name).utcOffset() / 60;
			const offsetSign = utcOffset >= 0 ? '+' : '';
			const zone = {
				name: name,
				utcOffset: utcOffset,
				description: name + ' (UTC ' + offsetSign + utcOffset + ')'
			};
			return zone;
		} else {
			return null;
		}
	});

	const timezoneOptions: object[] = [];
	_availableTZ.map(zone => {
		timezoneOptions.push({ value: zone?.name, label: zone?.description });
		return true;
	});

	const [showTimePicker, setShowTimePicker] = useState(false);
	const [pickedDate, setPickedDate] = useState<Date | null>(null);
	const [displayDate, setDisplayDate] = useState(moment(new Date()).toDate());
	const [timezone, setTimezone] = useState({});
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [selectedLength, setSelectedLength] = useState(props.appointmentLengths?.[0] ?? null);

	useEffect(() => {
		const defaultTimezoneObj = _availableTZ.find(timezoneObj => {
			if (timezoneObj && timezoneObj.name === props.timezone) {
				return true;
			}
			return false;
		});

		if (defaultTimezoneObj) {
			setTimezone({ value: defaultTimezoneObj?.name, label: defaultTimezoneObj?.description });
		}
	}, []);

	useEffect(() => {
		if (props.colors) {
			document.documentElement.style.setProperty('--selected-day', props.colors.butttonBg);
		}
	}, []);

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize, { passive: true });

		return () => {
			window.addEventListener('resize', handleResize, { passive: true });
		};
	}, []);

	// Ugly fix for today being marked as "passed" in Calendar-component
	useEffect(() => {
		const todayElem = document.querySelector('.Calendar__Day--today');

		if (todayElem) {
			if (todayElem.classList.contains('Calendar__Day--passed'))
				todayElem.classList.remove('Calendar__Day--passed');
			if (todayElem.classList.contains('Calendar__Day--today'))
				todayElem.classList.remove('Calendar__Day--today');
		}
	});

	const handleDateSelected = (date: any) => {
		setPickedDate(date);
		props.setDate(moment(date).format());
		setShowTimePicker(true);
	};

	const handleMonthChanged = (date: any) => {
		setDisplayDate(date);
	};

	const handleTimezoneChange = (value: { value: string; label: string }) => {
		const tempTimezone = _availableTZ.find(timezoneObj => {
			if (timezoneObj && timezoneObj.name === value.value) {
				return true;
			}
			return false;
		});
		if (tempTimezone) {
			props.setTimezone(tempTimezone.name);
		}
	};

	const availableDates = useMemo(
		() =>
			_.uniq(
				Object.values(props.appointmentTimes)
					.map((dates: any) => {
						return Object.keys(dates);
					})
					.flat()
			),
		[props.appointmentTimes]
	);

	// Show the first available date in the calendar if we haven't already picked a date
	useEffect(() => {
		if (availableDates.length && !availableDates.includes(moment(pickedDate).format('YYYY-MM-DD'))) {
			setDisplayDate(moment(availableDates[0]).toDate());
		}
	}, [availableDates]);

	return (
		<div className={`BookingCalendar ${showTimePicker ? 'showTimePicker' : ''}`}>
			<div className="BookingTimePickerWrapper">
				{showTimePicker ? (
					<BookingTimePicker
						date={pickedDate}
						data-testid="BookingTimePicker"
						setTime={props.setTime}
						setFormActive={props.setFormActive}
						appointmentTimes={selectedLength ? props.appointmentTimes[selectedLength] : {}}
						colors={props.colors}
						typography={props.typography}
						appointmentLengths={props.appointmentLengths}
						setAppointmentTimes={setSelectedLength}
					/>
				) : null}
			</div>
			<div className="BookingCalendarContent">
				<Title
					className="SelectDateTitle"
					size="xl"
					color={showTimePicker ? 'grey' : 'dark-grey'}
					data-testid="SelectDateTitle"
					style={getStyle('headlines', 'headlineText', props.colors, props.typography)}
				>
					{t('bookingCalendar.selectDate')}
				</Title>
				<div className="selectDateContainer">
					<CalendarHeader
						data-testid="CalendarHeader"
						date={displayDate}
						showMonths={false}
						className="CalendarHeader"
						onDateChange={(date: any) => {
							handleMonthChanged(date);
						}}
					/>
					<Calendar
						min={moment().toDate()}
						onSelect={handleDateSelected}
						displayDate={displayDate}
						selected={pickedDate ? pickedDate : null}
						data-testid="BookingCalendar"
						availableDates={availableDates}
						fullNameHeaders={!((showTimePicker && windowWidth < 1300) || windowWidth < 900)}
						weekIndicator={t('bookingCalendar.weekAbbreviation')}
						showWeekNumbers
					/>
				</div>
				<SearchableSelect
					default={timezone}
					options={timezoneOptions}
					onChange={setTimezone}
					setTimezone={handleTimezoneChange}
				/>
			</div>
		</div>
	);
}

export default BookingCalendar;
